import { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import dummyImg from "../../../assets/images/jcb.jpg";
import chatIcon from "../../../assets/images/chat-icon.png";
// import dummyProfile from "../../../assets/images/dummy-profile-pic.jpeg";
import calendarIcon from "../../../assets/images/calendar-icon.png";
import locationIcon from "../../../assets/images/location-white-icon.png";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { convertTimeZoneShortName } from "utils/commonFxns";
import ConfirmationModal from "./ConfirmationModal";
import { useSelector } from "react-redux";
import { IStoreValue } from "utils/types";
import CountdownTimer from "components/common/CountDownTimer";
const RentalDetailPopup = ({
  detailModalIsOpen,
  handleDetailModalClose,
  detailModalData,
  handleNotificationRedirection,
  setActionType,
  handleModalConfirmation,
  isConfirmationOpen,
  handleConfirmationClose,
  handleConfirmationOpen,
  handleMapRedirection,
  loading,
}) => {
  const AuthUserId = useSelector(
    (state: IStoreValue) => state.equipmentReducer.Authuser
  );
  const detailModalDataObj = detailModalData[0];
  const [confirmationMessage, setConfirmationMessage] = useState<string>("");
  let devilry = 0;
  let minimun = 0;
  let totalMi = 0;
  let _changeInTotal = 0;
  let refunded_protection = "";
  let grand_total = 0;
  if (detailModalDataObj && Object.entries(detailModalDataObj).length > 0) {
    devilry = detailModalDataObj?.["delivery_charges"] * 2;
    minimun = detailModalDataObj?.["minimum_delivery_fees"];
    totalMi = devilry + minimun;
    _changeInTotal = (totalMi / 100) * 10;
    refunded_protection =
      detailModalDataObj["refunded_protection"] != undefined ||
      detailModalDataObj["refunded_protection"] != ""
        ? detailModalDataObj["refunded_protection"]
        : "";
    grand_total =
      detailModalDataObj["protection_fees_refunded"] != "1"
        ? detailModalDataObj["admin_commission"] != null
          ? detailModalDataObj["total_price"] +
            detailModalDataObj["equipment_protection_fees"] +
            _changeInTotal +
            detailModalDataObj["admin_commission"]
          : detailModalDataObj["total_price"] +
            detailModalDataObj["equipment_protection_fees"]
        : detailModalDataObj["admin_commission"] != null
        ? detailModalDataObj["total_price"] -
          (detailModalDataObj["order_status"] == "order_canceled"
            ? detailModalDataObj["security_deposit"]
            : 0) +
          _changeInTotal +
          detailModalDataObj["admin_commission"] +
          (detailModalDataObj["protection_fees_refunded"] == "1"
            ? detailModalDataObj["equipment_protection_fees"] -
              (refunded_protection == ""
                ? detailModalDataObj["equipment_protection_fees"]
                : refunded_protection)
            : 0)
        : detailModalDataObj["total_price"];
  }
  return (
    <Modal
      open={detailModalIsOpen}
      onClose={handleDetailModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "80%",
            sm: "400px",
            md: "500px",
            lg: "500px",
            xl: "500px",
          },
          bgcolor: "#2e2e2e",
          border: "5px solid #faa61a",
          borderRadius: "10px",
          outline: "none !important",
          p: 3,
        }}
      >
        <IconButton
          onClick={handleDetailModalClose}
          sx={{
            position: "absolute",
            top: "-20px",
            right: "-20px",
            bgcolor: "#faa61a",
            color: "#000",
            "&:hover": {
              bgcolor: "#faa61a",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            color: "#fff",
            fontSize: {
              xs: "18px",
              sm: "24px",
              md: "24px",
              lg: "28px",
              xl: "28px",
            },
            fontWeight: "700",
            fontFamily: "Montserrat !important",
          }}
        >
          {detailModalDataObj?.request_type === "main_order"
            ? "Rental Request Detail"
            : "Extended Rental Request Detail"}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
            mt: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Box
              component={"img"}
              src={detailModalDataObj?.thumbnail}
              sx={{
                width: {
                  xs: "55px",
                  sm: "70px",
                  md: "75px",
                  lg: "80px",
                  xl: "85px",
                },
                height: {
                  xs: "45px",
                  sm: "60px",
                  md: "65px",
                  lg: "70px",
                  xl: "75px",
                },
                objectFit: "cover",
                borderRadius: "5px",
                border: "2px solid #faa61a",
                boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.5)",
              }}
            />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1px" }}>
              <Typography
                sx={{
                  color: "#faa61a",
                  fontWeight: "700",
                  fontSize: {
                    xs: "14px",
                    sm: "18px",
                    md: "20px",
                    lg: "23px",
                    xl: "24px",
                  },
                  fontFamily: "Montserrat !important",
                }}
              >
                {detailModalDataObj?.make ? detailModalDataObj?.make : "N/A"}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                Order ID #
                {detailModalDataObj?.request_type === "extend_order"
                  ? detailModalDataObj?.parent_order_id
                  : detailModalDataObj?.order_id}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* owner detail start */}
        {detailModalDataObj?.showBtn && (
          <Box sx={{ mt: 2, mb: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Rental:
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  ${detailModalDataObj?.price?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Security Deposit:
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  ${detailModalDataObj?.security_deposit?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Rental Admin Fee:
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  -${detailModalDataObj?.admin_commission?.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Equipment Protection:
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Paid by Renter
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                borderColor: "#faa61a",
                borderWidth: "1.2px",
                mt: 1.5,
                mb: 1.5,
              }}
            />

            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              {detailModalDataObj?.request_type === "main_order" && <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Transport:
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  $
                  {detailModalDataObj?.delivery_required === "1"
                    ? (
                        parseFloat(detailModalDataObj?.["delivery_charges"]) +
                        parseFloat(detailModalDataObj?.["pickup_chages"]) +
                        parseFloat(
                          detailModalDataObj?.["minimum_delivery_fees"]
                        )
                      )?.toFixed(2)
                    : "0.00"}
                </Typography>
              </Box>}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Transport Admin Fee:
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  -$
                  {parseFloat(
                    detailModalDataObj?.admin_transport_commission
                  )?.toFixed(2)}
                </Typography>
              </Box>
            </Box>
            <Divider
              sx={{
                borderColor: "#faa61a",
                borderWidth: "1.2px",
                mt: 1.5,
                mb: 1.5,
              }}
            />

            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "1rem",
                }}
              >
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  Total
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: "700",
                    fontFamily: "Montserrat !important",
                  }}
                >
                  ${detailModalDataObj?.total_price?.toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {/* owner detail end */}

        {/* renter detail start */}
        {!detailModalDataObj?.showBtn && (
          <Box className="">
            <Box
              // className="new-rate-box"
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                mt: 2,
                mb: 2,
              }}
            >
              <Box
                // className="rate-list-grid"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ color: "#fff" }}>Status : </Typography>
                <Typography
                  sx={{
                    color: "#faa61a",
                    textTransform: "uppercase",
                  }}
                >
                  PENDING
                </Typography>
              </Box>
              <Box
                // className="rate-list-grid"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Transport :{" "}
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  {detailModalDataObj?.delivery_required === "1"
                    ? "Owner"
                    : "Renter"}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Rental Price:
                </Typography>

                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  {/* ${OrderDetailPopData.rental_price.toFixed(2)} */}$
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(detailModalDataObj?.price)}
                </Typography>
              </Box>
              <Box
                // className="rate-list-grid"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  Security Deposit:{" "}
                </Typography>

                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  {/* ${OrderDetailPopData.security_deposit.toFixed(2)} */}$
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(detailModalDataObj?.security_deposit)}
                </Typography>
              </Box>

              {!isNaN(
                parseFloat(detailModalDataObj?.equipment_protection_fees)
              ) && (
                <Box
                  // className="rate-list-grid"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    EquipMe Protection{":"}
                  </Typography>

                  <Typography
                    sx={{
                      color: "#faa61a",
                      fontFamily: "Montserrat !important",
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    $
                    {new Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(
                      parseFloat(detailModalDataObj?.equipment_protection_fees)
                    )}
                  </Typography>
                </Box>
              )}

              <Box
                // className="rate-list-grid"
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {detailModalDataObj?.delivery_required === "1" && (
                  <>
                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                      }}
                    >
                      Transport Charges:
                    </Typography>

                    <Typography
                      sx={{
                        color: "#fff",
                        fontFamily: "Montserrat !important",
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                      }}
                    >
                      $
                      {new Intl.NumberFormat("en-US", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                      }).format(
                        detailModalDataObj?.["delivery_charges"] * 2 +
                          detailModalDataObj?.["minimum_delivery_fees"]
                      )}
                    </Typography>
                  </>
                )}
              </Box>
              <Divider
                sx={{
                  borderColor: "#faa61a",
                  borderWidth: "1.2px",
                  mt: 1,
                  mb: 1,
                }}
              />

              <Box
                // className="rate-list-grid g-total"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: "#faa61a",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: "800",
                  }}
                >
                  Total
                </Typography>

                <Typography
                  sx={{
                    color: "#fff",
                    fontFamily: "Montserrat !important",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: "800",
                  }}
                >
                  $
                  {new Intl.NumberFormat("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                  }).format(grand_total)}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
        {/* renter detail end */}

        {/* Rental Dates */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "1rem",
            mt: 2,
            mb: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
              <Box
                component={"img"}
                src={calendarIcon}
                alt=""
                sx={{
                  width: {
                    xs: "12px",
                    sm: "14px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  mt: "2px",
                }}
              />
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                Rental Dates:
              </Typography>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "2px" }}>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                Start:{" "}
                {/* {moment(
                  `${detailModalDataObj?.start_date} ${detailModalDataObj?.start_time}`
                ).format("MM/DD hh:mm A")} */}
                {detailModalDataObj?.timezone
                  ? convertTimeZoneShortName(
                      momentTimezone
                        .tz(
                          `${detailModalDataObj?.start_date} ${detailModalDataObj?.start_time}`,
                          detailModalDataObj?.timezone
                        )
                        .format("MM/DD hh:mm A   (z)")
                    )
                  : moment(
                      `${detailModalDataObj?.start_date} ${detailModalDataObj?.start_time}`
                    ).format("MM/DD hh:mm A (UTC)")}
              </Typography>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "13px",
                    lg: "14px",
                    xl: "14px",
                  },
                }}
              >
                End:{" "}
                {/* {moment(
                  `${detailModalDataObj?.end_date} ${detailModalDataObj?.end_time}`
                ).format("MM/DD hh:mm A")} */}
                {detailModalDataObj?.timezone
                  ? convertTimeZoneShortName(
                      momentTimezone
                        .tz(
                          `${detailModalDataObj?.end_date} ${detailModalDataObj?.end_time}`,
                          detailModalDataObj?.timezone
                        )
                        .format("MM/DD hh:mm A (z)")
                    )
                  : moment(
                      `${detailModalDataObj?.start_date} ${detailModalDataObj?.start_time}`
                    ).format("MM/DD hh:mm A (UTC)")}
              </Typography>
            </Box>

            {/* <Typography
              sx={{
                color: "#faa61a",
                textDecoration: "underline",
                textDecorationColor: "#faa61a",
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "13px",
                  lg: "14px",
                  xl: "14px",
                },
              }}
            >
              View Item Calendar
            </Typography> */}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "baseline",
                  flexDirection: "column",
                  gap: "4px",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
                >
                  <Box
                    component={"img"}
                    src={locationIcon}
                    alt=""
                    sx={{
                      width: {
                        xs: "12px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      mt: "2px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    {AuthUserId === detailModalDataObj?.owner_id
                      ? detailModalDataObj?.delivery_required === "1"
                        ? "Deliver to"
                        : "Renter Pickup"
                      : "Pickup / Delivery"}
                    {":"}
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    color:
                      AuthUserId === detailModalDataObj?.owner_id
                        ? "#faa61a"
                        : "#fff",
                    textDecoration:
                      AuthUserId === detailModalDataObj?.owner_id
                        ? "underline"
                        : "none",
                    textDecorationColor:
                      AuthUserId === detailModalDataObj?.owner_id
                        ? "#faa61a"
                        : "#fff",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                    cursor:
                      AuthUserId === detailModalDataObj?.owner_id
                        ? "pointer"
                        : "default",
                  }}
                  onClick={() => {
                    if (AuthUserId === detailModalDataObj?.owner_id) {
                      handleMapRedirection(detailModalDataObj);
                    }
                  }}
                >
                  {AuthUserId === detailModalDataObj?.owner_id
                    ? detailModalDataObj?.delivery_required === "1"
                      ? detailModalDataObj?.delivery_address
                      : detailModalDataObj?.equip_address
                    : "Awaiting Approval"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xs: "10px",
                      sm: "12px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  {detailModalDataObj?.delivery_required === "1"
                    ? "Owner provides transport"
                    : ""}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Accept/reject */}
        {/* {detailModalDataObj?.showBtn && ( */}
        <Box
          sx={{
            border: "3px solid #E61A23",
            borderRadius: "10px",
            padding: "0.5rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
            boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.5)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              // justifyContent: "center",
              gap: "1rem",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: {
                  xs: "50px",
                  sm: "60px",
                  md: "90px",
                  lg: "90px",
                  xl: "90px",
                },
              }}
            >
              <Box
                component={"img"}
                src={
                  detailModalDataObj?.showBtn
                    ? detailModalDataObj?.renter_profile_pic
                    : detailModalDataObj?.owner_profile_pic
                }
                alt=""
                sx={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  border: "2px solid #fff",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  fontWeight: "400",
                  fontSize: {
                    xs: "11px",
                    sm: "14px",
                    md: "16px",
                    lg: "17px",
                    xl: "18px",
                  },
                }}
              >
                {detailModalDataObj?.showBtn
                  ? detailModalDataObj?.renter?.replace(
                      detailModalDataObj?.renter[0],
                      detailModalDataObj?.renter[0]?.toUpperCase()
                    ) + " has"
                  : "You have"}
                {" a requested a rental."}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  mt: "10px",
                  mb: "10px",
                }}
              >
                <Box
                  component={"img"}
                  src={chatIcon}
                  alt=""
                  sx={{
                    //  marginTop:'-5px',
                    width: {
                      xs: "14px",
                      sm: "15px",
                      md: "18px",
                      lg: "20px",
                      xl: "20px",
                    },
                    // mt: "2px",
                  }}
                />
                <Typography
                  sx={{
                    color: "#faa61a",
                    textDecorationColor: "#faa61a",
                    textDecoration: "underline",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                      lg: "14px",
                      xl: "14px",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() =>
                    handleNotificationRedirection(detailModalDataObj)
                  }
                >
                  Message {detailModalDataObj?.showBtn ? "Renter" : "Owner"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            {detailModalData?.rentalRequestStatus ? (
              <Button
                sx={{
                  background:
                    detailModalData?.rentalRequestStatus === "Accepted"
                      ? "#008001"
                      : "#E61A23",
                  color: "#fff",
                  textTransform: "capitalize",
                  fontFamily: "Montserrat !important",
                  borderRadius: "8px",
                  fontWeight: "800",
                  padding: "7px 15px",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                  "&:hover": {
                    background:
                      detailModalData?.rentalRequestStatus === "Accepted"
                        ? "#006001"
                        : "#b81118",
                  },
                }}
              >
                {detailModalData?.rentalRequestStatus}
              </Button>
            ) : detailModalDataObj?.showBtn ? (
              <>
                <Button
                  sx={{
                    background: "#E61A23",
                    color: "#fff",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat !important",
                    borderRadius: "8px",
                    fontWeight: "800",
                    padding: "7px 15px",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                    "&:hover": {
                      background: "#b81118",
                    },
                  }}
                  onClick={() => {
                    setActionType("reject");
                    setConfirmationMessage("REJECT");
                    handleConfirmationOpen();
                  }}
                >
                  Reject
                </Button>
                <Button
                  sx={{
                    background: "#008001",
                    color: "#fff",
                    textTransform: "capitalize",
                    fontFamily: "Montserrat !important",
                    borderRadius: "8px",
                    fontWeight: "800",
                    padding: "7px 15px",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                      lg: "16px",
                      xl: "18px",
                    },
                    "&:hover": {
                      background: "#006001",
                    },
                  }}
                  onClick={() => {
                    setActionType("accept");
                    setConfirmationMessage("ACCEPT");
                    handleConfirmationOpen();
                  }}
                >
                  Accept
                </Button>
              </>
            ) : (
              <Button
                sx={{
                  textTransform: "capitalize",
                  background: "#E61A23",
                  color: "#fff",
                  fontFamily: "Montserrat !important",
                  borderRadius: "8px",
                  fontWeight: "800",
                  padding: "7px 15px",
                  fontSize: {
                    xs: "12px",
                    sm: "13px",
                    md: "14px",
                    lg: "16px",
                    xl: "18px",
                  },
                  "&:hover": {
                    background: "#b81118",
                  },
                }}
                onClick={() => {
                  setConfirmationMessage("CANCEL");
                  setActionType("cancel");
                  handleConfirmationOpen();
                }}
              >
                Cancel Request
              </Button>
            )}
          </Box>
        </Box>
        {/* )} */}

        {detailModalDataObj &&
          detailModalDataObj?.request_expire_at &&
          !detailModalDataObj?.showBtn && (
            <Box sx={{ color: "#fff", textAlign: "center", mt: 2 }}>
              <span>Note: </span>
              <span>
                {detailModalDataObj?.owner?.replace(
                  detailModalDataObj?.owner[0],
                  detailModalDataObj?.owner[0]?.toUpperCase()
                )}{" "}
                has
              </span>
              {detailModalIsOpen && (
                <CountdownTimer
                  expirationDateTime={detailModalDataObj["request_expire_at"]}
                  color="#faa61a"
                />
              )}
              <span>
                &nbsp;to accept this offer before it is withdrawn automatically
              </span>
            </Box>
          )}
        {detailModalDataObj &&
          detailModalDataObj?.request_expire_at &&
          detailModalDataObj?.showBtn && (
            <Box sx={{ color: "#fff", textAlign: "center", mt: 2 }}>
              <span>Note: </span>
              <span>
                The rental request will automatically cancel if not accepted in
                next
              </span>
              {detailModalIsOpen && (
                <CountdownTimer
                  expirationDateTime={detailModalDataObj["request_expire_at"]}
                  color="#faa61a"
                />
              )}
            </Box>
          )}

        <ConfirmationModal
          isConfirmationOpen={isConfirmationOpen}
          handleConfirmationClose={handleConfirmationClose}
          onConfirm={handleModalConfirmation}
          message={"Are you sure want to " + confirmationMessage + "?"}
          loading={loading}
        />
      </Box>
    </Modal>
  );
};

export default RentalDetailPopup;
